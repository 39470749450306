import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '30, 49, 72',
		'primary-dark': '75, 111, 108',
		'accent': '201, 158, 102',
		'accent-plus': '255, 255, 255',
	},
});
